.Section__2 {
  background-color: #A8C256;
}
.Section__3 {
  background-color: #607196;
}


.-rotate-180 {
  transform: rotate(-180deg);
}
