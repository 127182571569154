.LandingHeart {
  /*background-color: #C41E3A;*/
  max-width: 100vw;
  max-height: 100vh;
  overflow: hidden;
}

.LandingHeart__Heart {
  transition: all 1s ease-in-out;
  top: 50%;
  left: 50%;
  margin-left: -300px;
  margin-top: -270px;
  width: 600px;
  height: auto;
}

.LandingHeart__Shape {
  transition: all 1s ease-in-out;
}

.LandingHeart__Shape--animate #generated_shape_path,
.LandingHeart__Shape--animate #generated_shape_path_clipped {
  stroke-dashoffset: 0;
}

.LandingHeart__Shape--animated:hover #generated_shape_path_clipped {
  transition: all 0.4s ease-in-out !important;
  stroke-opacity: 0;
}

.LandingHeart__Heart--big {
  transform: scale(5);
}

.LandingHeart__Heart--small {
  transform: scale(0.15);
  top: 3rem;
  left: 4rem;
}

.LandingHeart__Heart--animated {
  z-index: 10;
  transform: scale(0.15);
  top: 3rem;
  left: 4rem;
}

.LandingHeart__Title {
  font-size: 80px;
  line-height: 60px;
  width: 310px;
  text-align: center;
  margin-top: -3rem;
}

.LandingHeart__Arrow {
  bottom: 4rem;
  transition: opacity 0.75s linear;
}

@media screen and (max-width: 1024px) {
  .LandingHeart__Heart {
    margin-left: -37.5%;
    margin-top: -34%;
    width: 75%;
    height: auto;
  }

  .LandingHeart__Heart--small {
    transform: scale(0.15);
    top: 1.25rem;
    left: 2rem;
  }

  .LandingHeart__Heart--animated {
    z-index: 10;
    transform: scale(0.15);
    top: 1.25rem;
    left: 2rem;
  }

  .LandingHeart__Shape:hover #generated_shape_path_clipped {
    transition: all 3s ease-in-out !important;
    stroke-opacity: 0;
  }
}
